import React, { ReactNode, useEffect } from "react";
import { obsoleteWarning } from "@telia/b2x-obsolete-component";

import "./b2x-telia-grid.scss";

export declare type ColumnWidthType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type Props = {
  children: ReactNode;
  ["data-testid"]?: string;
  className?: string;
};

type ColProps = {
  children: ReactNode;
  ["data-testid"]?: string;
  width?: ColumnWidthType;
  widthMd?: ColumnWidthType;
  widthLg?: ColumnWidthType;
  className?: string;
};

export const TeliaGrid = ({ children, ["data-testid"]: dataTestId, className = "" }: Props) => {
  useEffect(() => {
    obsoleteWarning("b2x-telia-grid", "components-grid--docs");
  }, []);

  return (
    <div className={`b2x-telia-grid ${className}`} data-testid={dataTestId}>
      {children}
    </div>
  );
};

export const TeliaRow = ({ children, ["data-testid"]: dataTestId, className = "" }: Props) => {
  useEffect(() => {
    obsoleteWarning("b2x-telia-row", "components-grid--docs");
  }, []);

  return (
    <div className={`b2x-telia-row ${className}`} data-testid={dataTestId}>
      {children}
    </div>
  );
};

export const TeliaCol = ({
  children,
  ["data-testid"]: dataTestId,
  width,
  widthMd,
  widthLg,
  className = "",
}: ColProps) => {
  useEffect(() => {
    obsoleteWarning("b2x-telia-col", "components-grid--docs");
  }, []);

  return (
    <div
      className={`b2x-telia-col
        ${width ? `b2x-telia-col-${width}` : ""}
        ${widthMd ? `b2x-telia-col-md-${widthMd}` : ""}
        ${widthLg ? `b2x-telia-col-lg-${widthLg}` : ""}
        ${className}`}
      data-testid={dataTestId}
    >
      {children}
    </div>
  );
};
